<template>
  <div class="user-info">
    <div class="user-info-self">
      <div class="user-info-base">
        <div class="user-info-content">
          <div class="user-info-c-head">
            <el-avatar
              style="width: 160px;height:160px;"
              :src="userInfo.avatar"
            ></el-avatar>
          </div>
          <p class="name">{{userInfo.realname}}</p>
          <div class="desc">
            <ul>
              <li><span>全部题目</span><label>{{questionInfo.total}}</label></li>
              <li><span>练习过的题目</span><label>{{questionInfo.hasAnswer}}</label></li>
              <li><span>回答错误题目</span><label>{{questionInfo.answerWrong}}</label></li>
              <li><span>回答错误率</span><label>{{questionInfo.wrongRate}}</label></li>
              <li><span>收藏的题目数量</span><label>{{questionInfo.hasFavorite}}</label></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="user-info-body">
      <template>
        <Question-list
          :isUserInfo="true"
          @init="getUserQuestionInfo"
        ></Question-list>
      </template>
    </div>
  </div>
</template>

<script>
import QuestionList from '@/components/QuestionList'

import Curriculums from '@/components/Curriculums';
import { getUserQuestionData, getUserInfo, changeNickname } from "@/api/user"
import Vue from 'vue'
import { getUserInfo as getUserBaseInfo } from '@/utils/auth';
export default {
  name: 'User-Info',
  components: {
    Curriculums,
    QuestionList
  },
  data() {
    return {
      questionInfo: {
        total: 0,
        answerWrong: 0,
        hasAnswer: 0,
        hasFavorite: 0,
        wrongRate: 0
      },

      searchParams: {
        fileType: null,
        order: null,
        pageSize: 10,
        pageNo: 1
      },
      showDialog: false,
      dialogForm: {
        nickname: ''
      },
      isSelf: !this.$route.query.uid || this.$route.query.uid == Vue.ls.get('Login_Userid'),
      uid: this.$route.query.uid,
      userInfo: {
        name: '',
        avatar: '',
      },
      tableData: [],
      dataTotal: 0,
      changeRules: {
        nickname: [
          {
            validator: (rule, value, callback) => {
              if (value == '') {
                callback(new Error('请输入昵称'));
              } else if (!/^[^x00-xff]{1,6}$/.test(value)) {
                callback(new Error('请输入最多6个中文'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          },
        ],
      },
    };
  },
  watch: {
    $route(newRoute) {
      this.uid = newRoute.query.uid,
        this.init();
    },
    showDialog(value) {
      if (value) {
        this.dialogForm.nickname = this.userInfo.realname;
      }
    }
  },
  mounted() {
    this.searchParams.uid = this.uid || Vue.ls.get('Login_Userid');
    this.init();
  },
  computed: {
    userBaseInfo: function () {
      return getUserBaseInfo();
    },
  },
  methods: {
    init() {
      if (!this.uid && !Vue.ls.get('Login_Userid')) {
        this.$router.push(`/login?redirect_uri=${location.href}`)
      } else {
        this.searchParams.pageNo = 1;
        this.getUserInfo();
        this.getUserQuestionInfo();
      }
    },
    closeDialog() {
      // console.log(this.$refs.dialogForm)
      this.$refs.dialogForm.resetFields();
    },
    getUserInfo() {
      getUserInfo({ uid: this.uid || Vue.ls.get('Login_Userid') }).then(res => {
        if (res.success) {
          this.userInfo = res.result;
        }
      })
    },
    getUserQuestionInfo() {
      getUserQuestionData().then(res => {
        if (res.code == 1) {
          this.questionInfo = res.response;
          if (this.questionInfo.hasAnswer > 0) {
            this.questionInfo.wrongRate = Math.round(this.questionInfo.answerWrong / this.questionInfo.hasAnswer * 10000) / 100 + "%";

          } else {
            this.questionInfo.wrongRate = 0;
          }
        }
      })
    },
  },
};
</script>

<style lang="scss">
.user-info .el-form-item__error {
  white-space: initial;
}
</style>
<style lang="scss" scoped>
@import '~@/assets/css/views/user-info.scss';
.el-select {
  display: inline-block;
  position: relative;
}
.search-child {
  display: inline-block;
  vertical-align: top;
  float: right;
}

.content-pagination {
  margin-top: 20px;
  margin-bottom: 50px;
  justify-content: right;
  text-align: right;
}
</style>
